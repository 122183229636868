<template>
  <div>
    <Bar v-on:toggle-drawer="toggleBar"></Bar>
    
    <Menu
      :drawer="drawer"
      v-on:toggle="toggle"
      v-on:router-path="routerPath"
    ></Menu>

    <v-dialog v-model="info" max-width="350" @keydown.esc="info = false">
      <Info></Info>
    </v-dialog>

    <v-dialog
      v-model="feedback"
      max-width="800"
      @keydown.esc="feedback = false"
    >
      <Feedback></Feedback>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Bar from "@/components/common/home/Bar";
import Info from "@/components/common/home/Info";
import Feedback from "@/components/common/home/Feedback";
import Menu from "@/pages/technical_reports/MenuTechnicalReport";

export default {
  name: "PublicTechnicalReport",
  components: {
    Bar,
    Menu,
    Info,
    Feedback,
  },

  data() {
    return {
      info: false,
      drawer: true,
      feedback: false,
    };
  },

  created() {},

  computed: {
    ...mapGetters("publicTechnicalReportsYears", [
      "getPublicTechnicalReportsYears",
    ]),
  },

  methods: {
    ...mapActions("publicTechnicalReportsYears", [
      "ActionGetPublicTechnicalReportsYears",
    ]),
    async getAsyncPublicTechnicalReportsYears() {
      try {
        await this.ActionGetPublicTechnicalReportsYears();
      } catch (err) {
        console.log(err);
      }
    },
    toggleBar() {
      this.drawer = !this.drawer;
    },

    toggle(data) {
      switch (data) {
        case "drawer":
          this.drawer = !this.drawer;
          break;
        case "info":
          this.info = !this.info;
          break;
      }
    },

    routerPath(data) {
      console.log('data', data)
      this.$router.push(data);
    },
  },
};
</script>