var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "" },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-list",
            { attrs: { dense: "", "mt-3": "" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.showLogin()
                    }
                  }
                },
                [
                  _c("v-list-item-action", [_c("v-icon", [_vm._v("home")])], 1),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Página Inicial")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.showTechnicalReports()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("description")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Relatórios Técnicos")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.showPFG()
                    }
                  }
                },
                [
                  _c("v-list-item-action", [_c("v-icon", [_vm._v("list")])], 1),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("PFG")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.showInstructions()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("school")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Instruções")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.showInfo()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("help_outline")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Sobre")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }