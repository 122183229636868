<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-container>
      <v-list dense mt-3>
        <v-list-item link @click="showLogin()">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Página Inicial</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="showTechnicalReports()">
          <v-list-item-action>
            <v-icon>description</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Relatórios Técnicos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="showPFG()">
          <v-list-item-action>
            <v-icon>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>PFG</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="showInstructions()">
          <v-list-item-action>
            <v-icon>school</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Instruções</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="showInfo()">
          <v-list-item-action>
            <v-icon>help_outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sobre</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "MenuTechnicalReport",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    showInstructions() {
      this.$emit("router-path", { name: "InstructionsAuthors" });
    },
    showTechnicalReports() {
      this.$emit("router-path", { name: "TechnicalReports" });
    },
    showPFG() {
      this.$emit("router-path", { name: "InstructionsAuthorsPFG" });
    },
    showLogin() {
      this.$router.push({ name: "login" });
    },

    showFeedback() {
      this.$emit("toggle", "feedback");
    },

    showInfo() {
      this.$emit("toggle", "info");
    },
  },
};
</script>

<style>
</style>